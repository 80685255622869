import { NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../assets/img/logo.png';
import { useUser } from '../data/useUser';

export function NavBar() {
	const user = useUser();

	const login = () => {
		window.location.href = `${window.location.origin}/api/v1/auth/login?return=${encodeURIComponent(`${window.location}`)}`;
	};

	return (
		<Navbar expand={true} className="fixed-top navbar-dark bg-primary">
			<Container>
				<Navbar.Brand href="#/">
					<img src={logo} height="28" alt="DayZSA Launcher Logo" />{' '}
					DayZSA Launcher
				</Navbar.Brand>
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						<NavLink to="/" className="nav-link">
							Home
						</NavLink>
						<NavLink to="/servercheck" className="nav-link">
							Check Server
						</NavLink>
						<NavLink to="/sponsor" className="nav-link">
							Sponsor Server
						</NavLink>
						<NavLink to="/support" className="nav-link">
							Support
						</NavLink>
						<Nav.Link
							href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=95G5FZ8PSW63W"
							target="_blank"
							rel="noreferrer"
						>
							Donate
						</Nav.Link>
					</Nav>
					{user && (
						<Nav>
							<NavDropdown
								title={
									<>
										<img
											src={user.avatar}
											height="20"
											alt="Steam User Avatar"
										/>{' '}
										{user.displayName}
									</>
								}
								align={'end'}
							>
								<NavDropdown.Item href="#/user/listings">
									Listings
								</NavDropdown.Item>
								<NavDropdown.Item href="#/user/profiles">
									Profiles
								</NavDropdown.Item>
								<NavDropdown.Divider />
								<NavDropdown.Item href="/api/v1/auth/logout">
									Logout
								</NavDropdown.Item>
							</NavDropdown>
						</Nav>
					)}
					{!user && (
						<Nav>
							<Nav.Link href={`/api/v1/auth/login`}>
								Login
							</Nav.Link>
						</Nav>
					)}
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}
