import { useAppVersion } from '../data';

export function Footer() {
	const banner: any = undefined;
	const { data: appVersion } = useAppVersion();
	return (
		<>
			{banner && (
				<div id="sponsor">
					<hr />
					<p className="text-center">
						<a
							href={banner.linkUrl}
							target="_blank"
							rel="noreferrer"
						>
							<img src={banner.imageUrl} alt="Ad Banner" />
						</a>
					</p>
				</div>
			)}
			<footer className="footer">
				<div className="container">
					<p>
						<span className="text-muted">
							&copy; {new Date().getFullYear()}. All rights
							reserved. All trademarks are property of their
							respective owners.
						</span>
					</p>
					<p>
						<span className="text-muted">
							This website is not affiliated or authorized by
							Bohemia Interactive a.s. Bohemia Interactive, ARMA,
							DAYZ and all associated logos and designs are
							trademarks or registered trademarks of Bohemia
							Interactive a.s.
						</span>
					</p>
					{appVersion && (
						<p>
							<span className="text-muted">{appVersion}</span>
						</p>
					)}
				</div>
			</footer>
		</>
	);
}
