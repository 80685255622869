import { useMutation, useQueryClient } from 'react-query';
import { Profile } from '.';
import { apiPost } from './apiPost';

type ProfileUpdate = {
	profileId: number;
	profile: {
		serverName: string;
		overrideServerName: boolean;
		image: string;
		description: string;
		website: string;
		teamspeak: string;
		discord: string;
		active: boolean;
	};
};

export function useUpdateProfile() {
	const queryClient = useQueryClient();

	return useMutation<Profile, Error, ProfileUpdate>({
		mutationFn: (profile) =>
			apiPost(`/api/v1/user/servers/update`, profile),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['profiles'] });
		},
	});
}
