import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Profile, useProfile } from '../../data';
import { useUpdateProfile } from '../../data/useUpdateProfile';

export function EditProfileForm({ profile }: { profile: Profile }) {
	const navigate = useNavigate();

	const { mutate, isLoading, error, isSuccess } = useUpdateProfile();

	const [serverName, setServerName] = useState(profile.serverName || '');
	const [overrideServerName, setOverrideServerName] = useState(
		!!profile.overrideServerName,
	);
	const [image, setImage] = useState(profile.image || '');
	const [description, setDescription] = useState(profile.description || '');
	const [website, setWebsite] = useState(profile.website || '');
	const [teamspeak, setTeamspeak] = useState(profile.teamspeak || '');
	const [discord, setDiscord] = useState(profile.discord || '');
	const [active, setActive] = useState(profile.active);

	return (
		<>
			<form>
				<div className="form-group mb-3">
					<label htmlFor="serverName">Server Name</label>
					<input
						type="text"
						className="form-control"
						id="serverName"
						maxLength={200}
						name="serverName"
						placeholder="Server Name"
						value={serverName}
						onChange={(e) => setServerName(e.target.value)}
					/>
					<small className="form-text text-muted">
						To use a custom server name, enter it above and enable
						server name override.
					</small>
				</div>
				<div className="form-group form-check mb-3">
					<input
						type="checkbox"
						className="form-check-input"
						id="overrideServerName"
						name="overrideServerName"
						checked={overrideServerName}
						onChange={(e) =>
							setOverrideServerName(e.target.checked)
						}
					/>
					<label
						className="form-check-label"
						htmlFor="overrideServerName"
					>
						Enable Server Name Override
					</label>
					<br />
					<small className="form-text text-muted">
						If enabled, this server will appear in the launcher as
						the name above when the profile is active.
						<br />
						Changing the server name in the servers config will have
						no effect until you disable this feature.
						<br />
						This can take upto 30 minutes to take effect.
					</small>
				</div>
				<div className="form-group mb-3">
					<label htmlFor="image">Image URL</label>
					<input
						type="text"
						className="form-control"
						id="image"
						name="image"
						placeholder="Image URL"
						value={image}
						onChange={(e) => setImage(e.target.value)}
					/>
					<small className="form-text text-muted">
						Add your community/server logo to the server profile.
					</small>
				</div>
				<div className="form-group mb-3">
					<label htmlFor="description">Description</label>
					<textarea
						className="form-control"
						id="description"
						name="description"
						rows={5}
						placeholder="Description"
						value={description}
						onChange={(e) => setDescription(e.target.value)}
					/>
					<small className="form-text text-muted">
						Add some information about your server/community here.
						<br />
						You can use{' '}
						<a
							href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
							rel="noreferrer"
							target="_blank"
						>
							Markdown
						</a>{' '}
						to customise the appearance of the content.
					</small>
				</div>
				<div className="form-group mb-3">
					<label htmlFor="website">Website</label>
					<input
						type="text"
						className="form-control"
						id="website"
						name="website"
						placeholder="http://dayzsalauncher.com"
						value={website}
						onChange={(e) => setWebsite(e.target.value)}
					/>
					<small className="form-text text-muted">
						If your community has a website, add it here.
					</small>
				</div>
				<div className="form-group mb-3">
					<label htmlFor="teamspeak">Teamspeak</label>
					<input
						type="text"
						className="form-control"
						id="teamspeak"
						name="teamspeak"
						placeholder="ts.dayzsalauncher.com"
						value={teamspeak}
						onChange={(e) => setTeamspeak(e.target.value)}
					/>
					<small className="form-text text-muted">
						If your community has a Teamspeak, add it here.
					</small>
				</div>
				<div className="form-group mb-3">
					<label htmlFor="discord">Discord</label>
					<input
						type="text"
						className="form-control"
						id="discord"
						name="discord"
						placeholder="https://discord.gg/MZBFZVJ"
						value={discord}
						onChange={(e) => setDiscord(e.target.value)}
					/>
					<small className="form-text text-muted">
						If your community has a Discord, add it here.
					</small>
				</div>
				<div className="form-group form-check mb-3">
					<input
						type="checkbox"
						className="form-check-input"
						id="active"
						name="active"
						checked={active}
						onChange={(e) => setActive(e.target.checked)}
					/>
					<label className="form-check-label" htmlFor="active">
						Profile Active
					</label>
					<br />
					<small className="form-text text-muted">
						If enabled, this server will appear in the launcher with
						a profile button.
					</small>
				</div>

				{error && (
					<div className="alert alert-danger" role="alert">
						Something went wrong, {error.message}
					</div>
				)}
				{isSuccess && (
					<div className="alert alert-success" role="alert">
						Successfully updated profile
					</div>
				)}

				<div className="d-grid gap-2 col-6 mx-auto">
					<button
						type="submit"
						className="btn btn-primary sharp"
						disabled={isLoading}
						onClick={() =>
							mutate({
								profileId: profile.id,
								profile: {
									serverName,
									overrideServerName,
									image,
									description,
									website,
									teamspeak,
									discord,
									active,
								},
							})
						}
					>
						{isLoading && (
							<>
								<i className="fa fa-cog fa-spin"></i>{' '}
							</>
						)}
						Update Profile
					</button>
				</div>

				<p className="text-center">
					<button
						className="btn btn-link btn-sm"
						type="button"
						onClick={() => navigate(`/user/profiles`)}
					>
						back to profiles
					</button>
				</p>
			</form>
		</>
	);
}

export function EditProfile() {
	const { id } = useParams<{ id: string }>();
	const { data: profile, isLoading, error } = useProfile(parseInt(id!));

	return (
		<main
			role="main"
			className="container-fluid fullwidth-fluid-container page"
		>
			<div className="page-header bg2">
				<div className="container">
					<h2>Edit Server Profile</h2>
				</div>
			</div>
			<div className="container">
				{error && (
					<div className="alert alert-danger" role="alert">
						Something went wrong, {error.message}
					</div>
				)}
				{isLoading && <p>loading...</p>}
				{profile && (
					<>
						<p>
							When changing the listing server ip, the new server
							must be onine for the update to be successful.
						</p>
						<EditProfileForm profile={profile} />
					</>
				)}
				{!isLoading && !profile && (
					<div className="alert alert-danger" role="alert">
						Could not find profile with id {id}
					</div>
				)}
			</div>
		</main>
	);
}
