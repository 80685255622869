import { useQuery } from 'react-query';
import { apiGet } from './apiGet';
import { Profile } from './Profile';

export function useProfiles() {
	return useQuery<Profile[], Error>({
		queryKey: ['profiles'],
		queryFn: () => apiGet('/api/v1/user/servers'),
		cacheTime: 60000,
	});
}
