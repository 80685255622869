import { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useQueryServer } from '../data/useQueryServer';

export function ServerCheck() {
	const navigate = useNavigate();
	const params = useParams<{ endpoint: string }>();
	const endpoint = params.endpoint || '';
	const [value, setValue] = useState(endpoint);

	const {
		data: server,
		isFetching: isQuerying,
		error,
		fetch: query,
		reset,
	} = useQueryServer();

	useEffect(() => {
		if (endpoint !== '') {
			query(endpoint!);
		} else {
			reset();
		}
		setValue(endpoint);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endpoint]);

	return (
		<main
			role="main"
			className="container-fluid fullwidth-fluid-container page"
		>
			<div className="page-header bg5">
				<div className="container">
					<h2>Check Your Server</h2>
				</div>
			</div>
			<div className="container">
				<div className="form-group">
					<label htmlFor="serverEndpoint">
						Server IP:Steam Query Port
					</label>
					<input
						name="serverEndpoint"
						type="text"
						value={value}
						onChange={(e) => setValue(e.target.value)}
						className="form-control"
						id="serverEndpoint"
						placeholder="Server IP:Steam Query Port"
					/>
					<small
						id="serverEndpointHelp"
						className="form-text text-muted"
					>
						Please make sure you are using the correct Steam Query
						Port. <br />
						Once a server has been added it normally takes ~5
						minutes to appear on the launcher. <br />
						If you have problems please goto the{' '}
						<NavLink to="/support">support page</NavLink>.
					</small>
				</div>
				<div className="d-grid gap-2 col-6 mx-auto">
					<button
						className="btn btn-primary sharp"
						type="button"
						onClick={() =>
							endpoint === value
								? query(endpoint)
								: navigate(`/servercheck/${value}`)
						}
					>
						{isQuerying && <i className="fa fa-cog fa-spin"></i>}{' '}
						Check Server
					</button>
				</div>
				<p className="text-center">
					<button
						className="btn btn-link btn-sm"
						type="button"
						onClick={() => navigate(`/servercheck`)}
					>
						reset
					</button>
				</p>
				{error && (
					<div className="alert alert-danger" role="alert">
						There was a problem querying the server.
						<br />
						<strong>{`${error.message}`}</strong>.
					</div>
				)}
				{server && (
					<div>
						<div className="alert alert-success" role="alert">
							Added "{server && server.name}" successfully
						</div>
						<div className="server-info">
							<dl className="row">
								<dt className="col-3 text-end">IP</dt>
								<dd className="col-9">
									{server?.endpoint.ip}:
									{server?.endpoint.port}
								</dd>
								<dt className="col-3 text-end">Game</dt>
								<dd className="col-9">{server?.game}</dd>
								<dt className="col-3 text-end">Server Name</dt>
								<dd className="col-9">{server?.name}</dd>
								<dt className="col-3 text-end">Map</dt>
								<dd className="col-9">{server?.map}</dd>
								<dt className="col-3 text-end">Players</dt>
								<dd className="col-9">
									{server?.players}/{server?.maxPlayers}
								</dd>
								<dt className="col-3 text-end">Version</dt>
								<dd className="col-9">{server?.version}</dd>
								{server?.mods && server.mods.length > 0 && (
									<>
										<dt className="col-3 text-end">Mods</dt>
										<dd className="col-9">
											{server.mods.map((mod) => (
												<div key={mod.name}>
													{mod.name}
												</div>
											))}
										</dd>
									</>
								)}
							</dl>
						</div>
					</div>
				)}
			</div>
		</main>
	);
}
