import { NotFound } from '../pages/NotFound';
import { useUser } from '../data/useUser';

export function IsLoggedIn({ children }: { children?: React.ReactNode }) {
	const user = useUser();

	if (!user) {
		return (
			<NotFound message="You need to be logged in to view this page." />
		);
	}

	return <>{children}</>;
}
