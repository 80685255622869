import { useQuery } from 'react-query';
import { apiGet } from './apiGet';

export function useImpressionsPerDay() {
	const { data } = useQuery<{ impressions: number } | undefined, Error>(
		['impressionValue'],
		async () => {
			try {
				return await apiGet<{ impressions: number }>(
					'/api/v1/payment/impressions',
				);
			} catch (error) {
				return undefined;
			}
		},
		{
			retry: false,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			cacheTime: 0,
		},
	);
	return Math.round(data?.impressions || 0);
}
