import { Profile } from '.';
import { useMutation, useQueryClient } from 'react-query';
import { apiGet } from './apiGet';

export function useDeleteProfile() {
	const queryClient = useQueryClient();

	return useMutation<Profile, Error, number>({
		mutationFn: (id) => apiGet(`/api/v1/user/servers/delete/${id}`),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['profiles'] });
		},
	});
}
