export async function apiGet<T = any>(url: string) {
	const res = await fetch(url);
	if (res.status !== 200) {
		throw new Error(res.statusText);
	}
	const data = await res.json();

	if (data.status !== 0) {
		throw new Error(data.error);
	}

	return data.result as T;
}
