export function NotFound({ message }: { message?: string }) {
	return (
		<main
			role="main"
			className="container-fluid fullwidth-fluid-container page"
		>
			<div className="page-header bg5">
				<div className="container">
					<h2>Page Not Found</h2>
				</div>
			</div>
			<div className="container">
				<p>
					{message ||
						`We're sorry, but the page you requested could not be found.`}
				</p>
			</div>
		</main>
	);
}
