import { apiGet } from './apiGet';
import { useFetch } from './useFetch';
import { useCallback } from 'react';

export type Server = {
	gamePort: number;
	sponsor: boolean;
	profile: boolean;
	endpoint: {
		ip: string;
		port: number;
	};
	game: string;
	name: string;
	nameOverride: boolean;
	map: string;
	players: number;
	maxPlayers: number;
	environment: string;
	password: boolean;
	version: string;
	mission: string;
	vac: boolean;
	battlEye: boolean;
	firstPersonOnly: boolean;
	shard: string;
	timeAcceleration: number;
	time: string;
	mods: [
		{
			name: string;
			steamWorkshopId: number;
		},
	];
};

export function useQueryServer() {
	return useFetch<Server | undefined, [string?], Error>(
		useCallback((endpoint?: string) => {
			if (!endpoint) return undefined;
			return apiGet<Server>(`/api/v1/query/${endpoint}`);
		}, []),
	);
}
