import { RouterProvider, createHashRouter } from 'react-router-dom';
import { Layout } from './components/Layout';
import { IsLoggedIn } from './components/IsLoggedIn';
import { Home } from './pages/Home';
import { ServerCheck } from './pages/ServerCheck';
import { Support } from './pages/Support';
import { Sponsor } from './pages/Sponsor';
import { PaypalSuccess } from './pages/PaypalSuccess';
import { Listings } from './pages/user/Listings';
import { Profiles } from './pages/user/Profiles';
import { ClaimServer } from './pages/user/ClaimServer';
import { NotFound } from './pages/NotFound';
import { EditListing } from './pages/user/EditListing';
import { EditProfile } from './pages/user/EditProfile';
import { ClaimListing } from './pages/user/ClaimListing';

const routes = () =>
	createHashRouter([
		{
			path: '/',
			element: <Layout />,
			children: [
				{
					index: true,
					element: <Home />,
				},
				{
					path: '/home',
					element: <Home />,
				},
				{
					path: '/servercheck/:endpoint?',
					element: <ServerCheck />,
				},
				{
					path: '/sponsor/:endpoint?',
					element: <Sponsor />,
				},
				{
					path: '/support',
					element: <Support />,
				},
				{
					path: '/paypal/success',
					element: <PaypalSuccess />,
				},
				{
					path: '/user/listings',
					element: (
						<IsLoggedIn>
							<Listings />
						</IsLoggedIn>
					),
				},
				{
					path: '/user/listings/:id',
					element: (
						<IsLoggedIn>
							<EditListing />
						</IsLoggedIn>
					),
				},
				{
					path: '/user/claimlisting',
					element: (
						<IsLoggedIn>
							<ClaimListing />
						</IsLoggedIn>
					),
				},
				{
					path: '/user/profiles',
					element: (
						<IsLoggedIn>
							<Profiles />
						</IsLoggedIn>
					),
				},
				{
					path: '/user/profiles/:id',
					element: (
						<IsLoggedIn>
							<EditProfile />
						</IsLoggedIn>
					),
				},
				{
					path: '/user/claimserver',
					element: (
						<IsLoggedIn>
							<ClaimServer />
						</IsLoggedIn>
					),
				},
				{
					path: '*',
					element: <NotFound />,
				},
			],
		},
	]);

function App() {
	return <RouterProvider router={routes()} />;
}

export default App;
