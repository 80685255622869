import { useCallback, useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { Server, useImpressionsPerDay, useUser } from '../data';
import { apiPost } from '../data/apiPost';
import { apiGet } from '../data/apiGet';
import { RegionSelect, Regions } from '../components/RegionSelect';

type PaymentFormResponse = {
	action: string;
	fields: {
		business: string;
		cmd: string;
		currency_code: string;
		cancel_return: string;
		return: string;
		notify_url: string;
		amount: string;
		item_name: string;
		custom: string;
	};
};

function SponsorServerForm({
	value,
	setValue,
}: {
	value: string;
	setValue: (value: string) => void;
}) {
	return (
		<>
			<div className="form-group">
				<label htmlFor="serverEndpoint">
					Server IP:Steam Query Port
				</label>
				<input
					name="serverEndpoint"
					type="text"
					value={value}
					onChange={(e) => setValue(e.target.value)}
					className="form-control"
					id="serverEndpoint"
					placeholder="Server IP:Steam Query Port"
				/>
				<small id="serverEndpointHelp" className="form-text text-muted">
					Please make sure you are using the correct Steam Query Port.{' '}
					<br />
					Once a server has been added it normally takes ~5 minutes to
					appear on the launcher. <br />
					A sponsored listing lasts for 1 month. <br />
					If you have problems please goto the{' '}
					<NavLink to="/support">support page</NavLink>.
				</small>
			</div>
		</>
	);
}

function useSponsorQuery(endpoint: string) {
	const [value, setValue] = useState(endpoint);
	const [server, setServer] = useState<Server | undefined>();
	const [error, setError] = useState<string | undefined>();
	const [isQuerying, setIsQuerying] = useState(false);

	const [isSubmit, setIsSubmit] = useState(false);
	const [form, setForm] = useState<PaymentFormResponse>();
	const [amount, setAmount] = useState(5);
	const [regions, setRegions] = useState<Regions>({
		AF: true,
		NA: true,
		OC: true,
		AS: true,
		EU: true,
		SA: true,
	});

	const reset = () => {
		setValue('');
		setServer(undefined);
		setError(undefined);
		setIsQuerying(false);
		setIsSubmit(false);
		setForm(undefined);
		setAmount(5);
		setRegions({
			AF: true,
			NA: true,
			OC: true,
			AS: true,
			EU: true,
			SA: true,
		});
	};

	const query = useCallback(async () => {
		console.log('query', endpoint, new Date().toISOString());
		setServer(undefined);
		if (!endpoint) {
			setError('Invalid server ip:port');
			return;
		}
		setError(undefined);
		setIsQuerying(true);
		try {
			setServer(await apiGet<Server>(`/api/v1/query/${endpoint}`));
		} catch (err: any) {
			setError(err.message);
		} finally {
			setIsQuerying(false);
		}
	}, [endpoint]);

	useEffect(() => {
		if (endpoint !== '') {
			reset();
			query();
			setValue(endpoint);
		} else {
			reset();
		}
	}, [endpoint, query]);

	const submit = useCallback(async () => {
		setIsSubmit(true);
		if (!server) {
			setError('Invalid server');
			return;
		}
		setError(undefined);
		try {
			setForm(
				await apiPost<PaymentFormResponse>(`/api/v1/payment/create`, {
					server: `${server.endpoint.ip}:${server.endpoint.port}`,
					amount,
					regions: Object.entries(regions)
						.filter(([_, v]) => v)
						.map(([k, _]) => k),
					cancelUrl: `${window.location}`,
				}),
			);
		} catch (err: any) {
			setError(err.message);
		} finally {
			setIsSubmit(false);
		}
	}, [amount, regions, server]);

	return {
		value,
		setValue,
		error,
		isQuerying,
		query,
		server,
		reset,
		isSubmit,
		form,
		submit,
		amount,
		setAmount,
		regions,
		setRegions,
	};
}

function SponsorPayment(
	props: Omit<ReturnType<typeof useSponsorQuery>, 'form'> & {
		endpoint: string;
	},
) {
	const navigate = useNavigate();
	const {
		endpoint,
		value,
		setValue,
		error,
		isQuerying,
		query,
		server,
		reset,
		isSubmit,
		submit,
		amount,
		setAmount,
		regions,
		setRegions,
	} = props;

	const user = useUser();

	const impressionValue = useImpressionsPerDay();

	return (
		<>
			<SponsorServerForm value={value} setValue={setValue} />
			<div className="d-grid gap-2 col-6 mx-auto">
				<button
					className="btn btn-primary sharp"
					disabled={isQuerying}
					type="button"
					onClick={() =>
						endpoint === value
							? query()
							: navigate(`/sponsor/${value}`)
					}
				>
					{isQuerying && <i className="fa fa-cog fa-spin"></i>}{' '}
					Sponsor Server
				</button>
			</div>

			<p className="text-center">
				<button
					className="btn btn-link btn-sm"
					type="button"
					onClick={() => {
						reset();
						navigate(`/sponsor`);
					}}
				>
					reset
				</button>
			</p>

			{error && (
				<>
					<hr />
					<div className="alert alert-danger" role="alert">
						There was a problem querying the server.
						<br />
						<strong>{`${error}`}</strong>.
					</div>
				</>
			)}

			{server && (
				<div className="server-info">
					{!user && (
						<div className="alert alert-dark" role="alert">
							<strong>You are not logged in</strong>
							<br />
							This is not required but you will not be able to
							view or edit the server listing once the payment is
							complete.
						</div>
					)}
					<dl className="row">
						<dt className="col-3 text-end">IP</dt>
						<dd className="col-9">
							{server.endpoint.ip}:{server.endpoint.port}
						</dd>
						<dt className="col-3 text-end">Game</dt>
						<dd className="col-9">{server.game}</dd>
						<dt className="col-3 text-end">Server Name</dt>
						<dd className="col-9">{server.name}</dd>
						<dt className="col-3 text-end">Map</dt>
						<dd className="col-9">{server.map}</dd>
						<dt className="col-3 text-end">Players</dt>
						<dd className="col-9">
							{server.players}/{server.maxPlayers}
						</dd>
						<dt className="col-3 text-end">Version</dt>
						<dd className="col-9">{server.version}</dd>
					</dl>
					<div className="row">
						<div className="col-4 sponsor-amount">
							<h4>&pound;{amount}</h4>
							{impressionValue !== undefined && (
								<p>
									~ {impressionValue * amount} impressions per
									day (for all regions)
								</p>
							)}
						</div>
						<div className="col-8">
							<Form.Range
								min={5}
								max={250}
								step={5}
								value={amount}
								disabled={isSubmit}
								onChange={(e) =>
									setAmount(parseInt(e.target.value))
								}
							/>
						</div>
					</div>
					<p className="text-center">
						Select which regions you wish the listing to appear in.
					</p>
					<div className="row">
						<div className="col-4 offset-md-4">
							<RegionSelect
								disabled={isSubmit}
								value={regions}
								onChange={setRegions}
							/>
						</div>
					</div>
					<div className="d-grid gap-2 col-6 mx-auto">
						<button
							className="btn btn-primary sharp"
							type="button"
							disabled={isSubmit}
							onClick={() => submit()}
						>
							{isSubmit && (
								<>
									<i className="fa fa-cog fa-spin"></i>{' '}
								</>
							)}
							Pay
						</button>
					</div>
					<hr />
					<p>
						<strong>
							Impressions per day is an estimate for all regions
							and in not way guaranteed.
						</strong>
					</p>
					<p>
						<small>
							The first 6 servers on the launcher's list will be
							sponsored listings, every time the launcher is
							opened, the system will randomly pick 6 servers,
							weighted based on amount paid. So if 12 servers have
							paid the same amount, every 120 launcher opens, each
							server will appear approximately 60 times. There is
							a minimum amount of £5 per server per month.
						</small>
					</p>

					<p>
						<small>
							You can sponsor your server at any time, the active
							server listings will be combined. Server listings
							can be transferred to another server if logged in
							when original payment was made.
						</small>
					</p>

					<p>
						<small>
							Please note servers that have been offline for 3
							hours will be removed from the listing UNTIL the
							server is online again and the time will not be
							refunded.
						</small>
					</p>
				</div>
			)}
		</>
	);
}

export function Sponsor() {
	const params = useParams<{ endpoint: string }>();
	const endpoint = params.endpoint || '';
	const query = useSponsorQuery(endpoint);
	const form = useRef<HTMLFormElement>(null);
	useEffect(() => {
		if (query.form && form.current) {
			console.log('submitting form');
			form.current.submit();
		}
	}, [query.form]);
	return (
		<>
			{!query.form && (
				<main
					role="main"
					className="container-fluid fullwidth-fluid-container page"
				>
					<div className="page-header bg2">
						<div className="container">
							<h2>Sponsor Your Server</h2>
						</div>
					</div>
					<div className="container">
						<SponsorPayment {...query} endpoint={endpoint} />
					</div>
				</main>
			)}
			{query.form && (
				<main
					role="main"
					className="container-fluid fullwidth-fluid-container page"
				>
					<div className="page-header bg2">
						<div className="container">
							<h2>Transferring to PayPal</h2>
						</div>
					</div>
					<div className="container">
						<div className="lds-dual-ring"></div>
						<form
							action={query.form.action}
							method="POST"
							ref={form}
						>
							{Object.entries(query.form.fields).map(([k, v]) => (
								<input
									key={k}
									type="hidden"
									name={k}
									value={v}
								/>
							))}
						</form>
					</div>
				</main>
			)}
		</>
	);
}
