//import { StrictMode } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

dayjs.extend(relativeTime);

window.dataLayer = window.dataLayer || [];
function gtag(...args: any[]) {
	window.dataLayer.push(args);
}
gtag('js', new Date());
gtag('config', 'UA-1859702-51');

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
);

const queryClient = new QueryClient();

root.render(
	//<StrictMode>
	<QueryClientProvider client={queryClient}>
		<App />
	</QueryClientProvider>,
	//</StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
