import { NavLink } from 'react-router-dom';
import { useUser } from '../data';

export function PaypalSuccess() {
	const user = useUser();

	return (
		<main
			role="main"
			className="container-fluid fullwidth-fluid-container page"
		>
			<div className="page-header bg2">
				<div className="container">
					<h2>Payment Complete</h2>
				</div>
			</div>
			<div className="container">
				<p>
					Thank you for your support, you will receive an email once
					the payment has been confirmed by PayPal and the listing is
					active.
				</p>
				<p>
					If you have any problems please contact me at via the{' '}
					<NavLink to="/support">support page</NavLink>.
				</p>
				{user && (
					<p>
						You can now goto the{' '}
						<NavLink to="/user/listings">listings page</NavLink> and
						manage your server listing.
					</p>
				)}
			</div>
		</main>
	);
}
