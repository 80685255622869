import { useMutation, useQueryClient } from 'react-query';
import { Listing } from '.';
import { apiPost } from './apiPost';
import { Regions } from '../components/RegionSelect';

export type ListingUpdate = {
	listingId: number;
	server: string;
	regions: Regions;
};

export function useUpdateListing() {
	const queryClient = useQueryClient();
	return useMutation<Listing, Error, ListingUpdate>({
		mutationFn: (listing) =>
			apiPost(`/api/v1/user/listings/update`, {
				...listing,
				regions: Object.entries(listing.regions)
					.filter(([_, v]) => v)
					.map(([k, _]) => k),
			}),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['listings'] });
		},
	});
}
