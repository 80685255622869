import { useCallback, useState } from 'react';

export function useFetch<
	TQueryFnData = unknown,
	TQueryFnArgs extends any[] = [],
	TError = unknown,
>(queryFn: (...args: TQueryFnArgs) => TQueryFnData | Promise<TQueryFnData>) {
	const [data, setData] = useState<TQueryFnData | undefined>();
	const [error, setError] = useState<TError | undefined>();
	const [isFetching, setIsFetching] = useState(false);

	const fetch = useCallback(
		async (...args: TQueryFnArgs) => {
			setData(undefined);
			setError(undefined);
			setIsFetching(true);
			try {
				const data = await queryFn(...args);
				setData(data);
			} catch (error: any) {
				setError(error);
			}
			setIsFetching(false);
		},
		[queryFn],
	);

	return {
		data,
		error,
		isFetching,
		fetch: (...args: TQueryFnArgs) => {
			fetch(...args);
		},
		reset: () => {
			setData(undefined);
			setError(undefined);
		},
	};
}
