import launcherScreenshot from '../assets/img/launcher-screenshot1.png';
import { useLauncherVersion } from '../data/useLauncherVersion';

export function Home() {
	const { data } = useLauncherVersion();

	return (
		<main
			role="main"
			className="container-fluid fullwidth-fluid-container page"
		>
			<div className="frontpage-inner-bg {{bgimage}}">
				<div className="frontpage-inner">
					<h1>DayZ SA Launcher</h1>
					<p className="lead">
						An easy to use launcher for DayZ Standalone.
						<br />
						<small className="text-muted">
							&bull; with unofficial mod support &bull;
						</small>
					</p>
					<img
						src={launcherScreenshot}
						alt="Background"
						className="img-fluid launcher-screenshot"
					/>
				</div>
				<div id="download-button">
					{data && (
						<>
							<p className="text-center">
								<a
									href={`/releases/setup_dzsalauncher-${data.version}.exe`}
									className="btn btn-primary btn-lg sharp"
								>
									<i className="fas fa-arrow-alt-circle-down"></i>{' '}
									Download
								</a>
								<button className="btn btn-secondary btn-lg sharp">
									<i className="fas fa-question"></i> Support
								</button>
							</p>
							<p className="text-center">
								<small>
									<a
										href={`/releases/dzsalauncher-${data.version}.zip`}
									>
										Portable Version
									</a>
								</small>
								<br />
								<small>{data.version}</small>
							</p>
						</>
					)}
				</div>
			</div>
		</main>
	);
}
