export function Support() {
	return (
		<main
			role="main"
			className="container-fluid fullwidth-fluid-container page"
		>
			<div className="page-header bg4">
				<div className="container">
					<h2>Support</h2>
				</div>
			</div>
			<div className="container">
				<p>
					The best way for get support is to join our{' '}
					<a
						href="https://discord.gg/MZBFZVJ"
						target="_blank"
						rel="noreferrer"
					>
						Discord
					</a>
					.
				</p>
				<p>
					Please send email enquires to{' '}
					<a href="mailto:maca134@googlemail.com">maca134</a>.
				</p>
			</div>
		</main>
	);
}
