import { useMutation } from 'react-query';
import { apiGet } from './apiGet';

export function useClaimServer() {
	return useMutation<{ valid: boolean }, Error, string>({
		mutationKey: ['claimServer'],
		mutationFn: (server: string) =>
			apiGet(`/api/v1/user/servers/claimvalidate/${server}`),
	});
}
