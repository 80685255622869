import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useProfiles } from '../../data';
import { ConfirmButton } from '../../components/ConfirmButton';
import { useDeleteProfile } from '../../data/useDeleteProfile';

export function Profiles() {
	const navigate = useNavigate();
	const { data: profiles, isLoading, isError } = useProfiles();
	const { mutate: deleteProfile, isLoading: isDeleting } = useDeleteProfile();

	return (
		<main
			role="main"
			className="container-fluid fullwidth-fluid-container page"
		>
			<div className="page-header bg1">
				<div className="container">
					<h2>Server Profiles</h2>
				</div>
			</div>
			<div className="container">
				<p>
					In this section you can add a profile to your server once
					you have verified you are the owner.
				</p>
				<p>
					<button
						className="btn btn-sm btn-secondary"
						onClick={() => navigate('/user/claimserver')}
					>
						Claim Server
					</button>{' '}
					Claim a server to add a profile.
				</p>
				<table className="table">
					<thead>
						<tr>
							<th scope="col">Server</th>
							<th scope="col">Name</th>
							<th scope="col">Views</th>
							<th scope="col">Active</th>
							<th scope="col">Updated</th>
							<th scope="col"></th>
						</tr>
					</thead>
					<tbody>
						{isLoading && (
							<tr>
								<td colSpan={6}>Loading...</td>
							</tr>
						)}
						{isError && (
							<tr>
								<td colSpan={6}>Error loading listings.</td>
							</tr>
						)}
						{profiles && profiles?.length === 0 && (
							<tr>
								<td colSpan={6}>No profiles found.</td>
							</tr>
						)}
						{profiles &&
							profiles.length > 0 &&
							profiles.map((profile) => (
								<tr key={profile.id}>
									<td>{profile.server}</td>
									<td>
										<span>
											{profile.serverName ||
												'- not set -'}
										</span>
									</td>
									<td>{profile.impressions}</td>
									<td>
										{profile.activeListing
											? profile.active
												? 'Yes'
												: 'No'
											: 'No Active Listing'}
									</td>
									<td>
										{dayjs(profile.updatedDate).fromNow()}
									</td>
									<td>
										<button
											className="btn btn-sm btn-secondary"
											disabled={isDeleting}
											onClick={() =>
												navigate(
													`/user/profiles/${profile.id}`,
												)
											}
										>
											Edit
										</button>{' '}
										<ConfirmButton
											className="btn btn-sm btn-danger"
											disabled={isDeleting}
											confirm="Confirm?"
											onClick={() =>
												deleteProfile(profile.id)
											}
										>
											Delete
										</ConfirmButton>
									</td>
								</tr>
							))}
					</tbody>
				</table>
				<p>
					<small>
						Profiles will only display for servers with active
						server listings.
					</small>
				</p>
			</div>
		</main>
	);
}
