import { ReactNode, useEffect, useState } from 'react';

export function ConfirmButton({
	children,
	confirm,
	className,
	disabled,
	onClick,
}: {
	children: ReactNode;
	confirm: ReactNode;
	className?: string;
	disabled?: boolean;
	onClick: () => void;
}) {
	const [clicked, setClicked] = useState(false);

	const click = () => {
		if (clicked) {
			onClick();
			setClicked(false);
		} else {
			setClicked(true);
		}
	};

	useEffect(() => {
		if (!clicked) {
			return;
		}
		const timeout = setTimeout(() => {
			setClicked(false);
		}, 5000);
		return () => clearTimeout(timeout);
	}, [clicked]);

	return (
		<button
			className={className || 'btn btn-sm btn-link'}
			onClick={click}
			disabled={disabled}
		>
			{clicked ? confirm : children}
		</button>
	);
}
