import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useListings } from '../../data';

export function Listings() {
	const { data: listings, isLoading, isError } = useListings();
	const navigate = useNavigate();

	return (
		<main
			role="main"
			className="container-fluid fullwidth-fluid-container page"
		>
			<div className="page-header bg2">
				<div className="container">
					<h2>Listings</h2>
				</div>
			</div>
			<div className="container">
				<p>
					<button
						className="btn btn-sm btn-secondary"
						onClick={() => navigate('/user/claimlisting')}
					>
						Claim Listing
					</button>{' '}
					Claim a listing you paid for but were not logged for.
				</p>
				<p>
					In this section you can view and edit your active server
					listings.
				</p>
				<table className="table">
					<thead>
						<tr>
							<th scope="col">Server</th>
							<th scope="col">Amount</th>
							<th scope="col">Paypal Transaction ID</th>
							<th scope="col">Impressions</th>
							<th scope="col">Expiry</th>
							<th scope="col"></th>
						</tr>
					</thead>
					<tbody>
						{isLoading && (
							<tr>
								<td colSpan={6}>Loading...</td>
							</tr>
						)}
						{isError && (
							<tr>
								<td colSpan={6}>Error loading listings.</td>
							</tr>
						)}
						{listings && listings?.length === 0 && (
							<tr>
								<td colSpan={6}>No listings found.</td>
							</tr>
						)}
						{listings &&
							listings.length > 0 &&
							listings.map((listing) => (
								<tr
									key={listing.id}
									className={
										listing.isExpired ? 'inactive' : ''
									}
								>
									<td>{listing.server}</td>
									<td>
										{new Intl.NumberFormat('en-GB', {
											style: 'currency',
											currency: 'GBP',
										}).format(listing.amount)}
									</td>
									<td>{listing.transactionId}</td>
									<td>{listing.impressions}</td>
									<td>
										<span
											title={dayjs(
												listing.expiryDate,
											).format('DD/MM/YYYY HH:mm:ss')}
										>
											{listing.isExpired
												? 'Expired'
												: dayjs(
														listing.expiryDate,
													).fromNow()}
										</span>
									</td>
									<td>
										{!listing.isExpired && (
											<button
												className="btn btn-sm btn-secondary"
												onClick={() =>
													navigate(
														`/user/listings/${listing.id}`,
													)
												}
											>
												Edit
											</button>
										)}
									</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
		</main>
	);
}
