import { useQuery } from 'react-query';
import { apiGet } from './apiGet';

type User = {
	steamId: string;
	avatar: string;
	profileurl: string;
	displayName: string;
};

export function useUser() {
	const { data } = useQuery<User | undefined, Error>(
		['user'],
		async () => {
			try {
				return await apiGet<User>('/api/v1/auth/status');
			} catch (error) {
				return undefined;
			}
		},
		{
			refetchInterval: 60000,
		},
	);
	return data;
}
