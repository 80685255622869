export async function apiPost<T = any>(url: string, body: any) {
	const res = await fetch(url, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body),
	});
	if (res.status !== 200) {
		throw new Error(res.statusText);
	}
	const data = await res.json();

	if (data.status !== 0) {
		throw new Error(data.error);
	}

	return data.result as T;
}
