import { useState } from 'react';
import { useMutation } from 'react-query';
import { apiPost } from '../../data/apiPost';

export function ClaimListing() {
	const [email, setEmail] = useState('');
	const { mutate, isLoading, isSuccess, error } = useMutation<
		void,
		Error,
		string
	>({
		mutationKey: ['claimListing'],
		mutationFn: (email: string) =>
			apiPost('/api/v1/user/listings/claimrequest', { email }),
	});
	return (
		<main
			role="main"
			className="container-fluid fullwidth-fluid-container page"
		>
			<div className="page-header bg5">
				<div className="container">
					<h2>Claim Listing</h2>
				</div>
			</div>
			<div className="container">
				<p>
					You can use this form to claim any listings you have paid
					for but were not logged in for.
				</p>
				{error && (
					<div className="alert alert-danger" role="alert">
						Something went wrong, {error.message}
					</div>
				)}
				{isSuccess && (
					<div className="alert alert-success" role="alert">
						You should receive an email shortly with a link to claim
						your listings.
					</div>
				)}
				<form>
					<div className="form-group mb-3">
						<label htmlFor="email">Email</label>
						<input
							name="email"
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							className="form-control"
							id="email"
							placeholder="Email"
						/>
						<small
							id="serverEndpointHelp"
							className="form-text text-muted"
						>
							Please provide the email you used when paying for
							the listing(s).
						</small>
					</div>
					<div className="d-grid gap-2 col-6 mx-auto">
						<button
							className="btn btn-primary sharp"
							type="button"
							onClick={() => mutate(email)}
						>
							{isLoading && (
								<>
									<i className="fa fa-cog fa-spin"></i>{' '}
								</>
							)}{' '}
							Process Claim
						</button>
					</div>
				</form>
			</div>
		</main>
	);
}
