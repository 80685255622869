export type Regions = {
	AF: boolean;
	NA: boolean;
	OC: boolean;
	AS: boolean;
	EU: boolean;
	SA: boolean;
};

export function RegionSelect({
	value,
	onChange,
	disabled,
}: {
	value: Regions;
	onChange: (regions: Regions) => void;
	disabled: boolean;
}) {
	const allChecked = ['AF', 'NA', 'OC', 'AS', 'EU', 'SA'].every(
		(key) => !!value[key as keyof Regions],
	);

	const Chechbox = ({
		name,
		label,
	}: {
		name: keyof Regions;
		label: string;
	}) => (
		<div className="form-check inline-block">
			<input
				type="checkbox"
				className="form-check-input"
				id={`continents-${name}`}
				name={`continents-${name}`}
				checked={value[name]}
				disabled={disabled}
				onChange={(event) =>
					onChange({ ...value, [name]: event.target.checked })
				}
			/>
			<label className="form-check-label" htmlFor={`continents-${name}`}>
				{label}
			</label>
		</div>
	);

	const toggleAll = (state: boolean) => {
		onChange({
			AF: state,
			NA: state,
			OC: state,
			AS: state,
			EU: state,
			SA: state,
		});
	};

	return (
		<>
			<div className="text-center">
				<div className="form-check d-inline-block mx-auto">
					<input
						type="checkbox"
						className="form-check-input"
						id="continents-all"
						checked={allChecked}
						disabled={disabled}
						onChange={(event) => toggleAll(event.target.checked)}
					/>
					<label
						className="form-check-label"
						htmlFor="continents-all"
					>
						All Regions
					</label>
				</div>
			</div>
			<div className="row continent-checkboxes">
				<div className="col-6">
					<Chechbox name="EU" label="Europe" />
					<Chechbox name="NA" label="North America" />
					<Chechbox name="OC" label="Oceania" />
				</div>
				<div className="col-6">
					<Chechbox name="SA" label="South America" />
					<Chechbox name="AS" label="Asia" />
					<Chechbox name="AF" label="Africa" />
				</div>
			</div>
		</>
	);
}
