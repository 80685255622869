import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RegionSelect, Regions } from '../../components/RegionSelect';
import { Listing, useListing } from '../../data';
import { useUpdateListing } from '../../data/useUpdateListing';

export function EditListingForm({ listing }: { listing: Listing }) {
	const navigate = useNavigate();
	const [server, setServer] = useState(listing.server);
	const [regions, setRegions] = useState<Regions>(
		listing.regions.reduce((regions, region) => {
			regions[region.region as keyof Regions] = true;
			return regions;
		}, {} as Regions),
	);

	const { mutate, isLoading, error, isSuccess } = useUpdateListing();

	return (
		<>
			<form>
				<div className="form-group mb-3">
					<label htmlFor="server">Server IP:Query Port</label>
					<input
						type="text"
						className="form-control"
						id="server"
						name="server"
						value={server}
						onChange={(e) => setServer(e.target.value)}
						placeholder="Server IP:Query Port"
					/>
				</div>
				<div className="row mb-3">
					<div className="col-6 offset-md-3">
						<RegionSelect
							disabled={isLoading}
							value={regions}
							onChange={setRegions}
						/>
					</div>
				</div>

				{error && (
					<div className="alert alert-danger" role="alert">
						Something went wrong, {error.message}
					</div>
				)}
				{isSuccess && (
					<div className="alert alert-success" role="alert">
						Successfully updated listing
					</div>
				)}

				<div className="d-grid gap-2 col-6 mx-auto">
					<button
						type="submit"
						className="btn btn-primary sharp"
						disabled={isLoading}
						onClick={() =>
							mutate({ listingId: listing.id, server, regions })
						}
					>
						{isLoading && (
							<>
								<i className="fa fa-cog fa-spin"></i>{' '}
							</>
						)}
						Update Listing
					</button>
				</div>

				<p className="text-center">
					<button
						className="btn btn-link btn-sm"
						type="button"
						onClick={() => navigate(`/user/listings`)}
					>
						back to listings
					</button>
				</p>
			</form>
		</>
	);
}

export function EditListing() {
	const { id } = useParams<{ id: string }>();
	const { data: listing, isLoading, error } = useListing(parseInt(id!));

	return (
		<main
			role="main"
			className="container-fluid fullwidth-fluid-container page"
		>
			<div className="page-header bg2">
				<div className="container">
					<h2>Edit Server Listings</h2>
				</div>
			</div>
			<div className="container">
				{error && (
					<div className="alert alert-danger" role="alert">
						Something went wrong, {error.message}
					</div>
				)}
				{isLoading && <p>loading...</p>}
				{listing && (
					<>
						<p>
							When changing the listing server ip, the new server
							must be onine for the update to be successful.
						</p>
						<EditListingForm listing={listing} />
					</>
				)}
				{!isLoading && !listing && (
					<div className="alert alert-danger" role="alert">
						Could not find listing with id {id}
					</div>
				)}
			</div>
		</main>
	);
}
