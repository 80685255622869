import { useQuery } from 'react-query';
import { apiGet } from './apiGet';

export function useAppVersion() {
	return useQuery<string | undefined, Error>({
		queryKey: 'appVersion',
		queryFn: () => apiGet('/api/v1/app-version'),
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	});
}
