import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useClaimServer, useClaimServerName } from '../../data';

export function ClaimServer() {
	const navigate = useNavigate();
	const [server, setServer] = useState('');
	const { data: serverName } = useClaimServerName();
	const {
		mutate: claimServer,
		isLoading,
		error,
		isSuccess,
	} = useClaimServer();

	return (
		<main
			role="main"
			className="container-fluid fullwidth-fluid-container page"
		>
			<div className="page-header bg5">
				<div className="container">
					<h2>Claim Server</h2>
				</div>
			</div>
			<div className="container">
				<p>Please set the server name to:</p>
				<h3 className="serverName">
					{serverName || <i className="fa fa-cog fa-spin"></i>}
				</h3>
				<hr />
				<p>
					Once the server has been renamed and it is running, enter
					the <strong>server ip:steam query port</strong> to claim the
					server.
				</p>
				<p>
					After the claim process has been completed, you will be able
					to rename your server back to the original name.
				</p>
				<form>
					<div className="form-group">
						<label htmlFor="serverEndpoint">
							Server IP:Steam Query Port
						</label>
						<input
							name="serverEndpoint"
							type="text"
							className="form-control"
							id="serverEndpoint"
							aria-describedby="serverEndpointHelp"
							placeholder="Server IP:Steam Query Port"
							value={server}
							onChange={(e) => setServer(e.target.value)}
						/>
						<small
							id="serverEndpointHelp"
							className="form-text text-muted"
						>
							Please make sure you are using the correct Steam
							Query Port. <br />
							If you have problems please goto the{' '}
							<NavLink to="/support">support page</NavLink>.
						</small>
					</div>
					{error && (
						<div className="alert alert-danger" role="alert">
							Error claiming server: {error.message}
						</div>
					)}
					{isSuccess && (
						<div className="alert alert-success" role="alert">
							Server claimed successfully.
						</div>
					)}
					<div className="d-grid gap-2 col-6 mx-auto">
						<button
							type="submit"
							className="btn btn-primary sharp"
							disabled={isLoading}
							onClick={() => claimServer(server)}
						>
							{isLoading && (
								<>
									<i className="fa fa-cog fa-spin"></i>{' '}
								</>
							)}
							Claim Server
						</button>
					</div>

					<p className="text-center">
						<button
							className="btn btn-link btn-sm"
							type="button"
							onClick={() => navigate(`/user/profiles`)}
						>
							back to profiles
						</button>
					</p>
				</form>
			</div>
		</main>
	);
}
