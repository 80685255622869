import { useQuery } from 'react-query';
import { apiGet } from './apiGet';

export function useClaimServerName() {
	return useQuery<string, Error>({
		queryKey: ['claimServerName'],
		queryFn: () =>
			apiGet('/api/v1/user/servers/claimrequest').then(
				(data) => data.serverName,
			),
		cacheTime: 0,
		refetchOnWindowFocus: false,
	});
}
