import { useQuery } from 'react-query';
import { apiGet } from './apiGet';
import { Listing } from './Listing';

export function useListings() {
	return useQuery<Listing[], Error>({
		queryKey: ['listings'],
		queryFn: () => apiGet('/api/v1/user/listings'),
		cacheTime: 60000,
	});
}
